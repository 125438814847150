<template>
  <router-view v-slot="{ Component }" :class="isAdminPage ? '' : ''">
    <component :is="Component" />
  </router-view>
  <AppFooter v-if="!isAdminPage" class="sticky top-[100vh] w-full" />
  <AppToast />
</template>

<script>
const app = document.getElementsByTagName('BODY')[0];

let currentLang = localStorage.getItem('nasr_language');
if (currentLang == null) {
  currentLang = 'en';
}
if (currentLang == 'ar') {
  $('body').addClass('rtl');
  $('html').attr('data-lang', 'rtl');
  $('html').attr('lang', 'ar');
} else {
  $('body').removeClass('rtl');
  $('html').removeAttr('data-lang');
}

import { mapGetters, mapActions } from 'vuex';
import AppNav from '@components/AppNav.vue';
import AppFooter from '@components/AppFooter.vue';
import VerifyNotice from '@components/VerifyNotice.vue';
import OctoCat from '@components/OctoCat.vue';

export default {
  components: {
    AppNav,
    VerifyNotice,
    AppFooter,
    OctoCat,
  },
  computed: {
    ...mapGetters({
      darkMode: 'auth/darkMode',
      user: 'auth/user',
    }),
    currentRouteName() {
      return this.$route.name;
    },
    isAdminPage() {
      if (
        this.currentRouteName == 'admin' ||
        this.currentRouteName == 'roles' ||
        this.currentRouteName == 'permissions' ||
        this.currentRouteName == 'settings' ||
        this.currentRouteName == 'users' ||
        this.currentRouteName == 'app-settings' ||
        this.currentRouteName == 'phpinfo'
      ) {
        return true;
      }
      return false;
    },
    showOctoCat() {
      if (
        this.currentRouteName == 'home' ||
        this.currentRouteName == 'about' ||
        this.currentRouteName == 'terms'
      ) {
        return true;
      }
      return false;
    },
  },
  updated() {
    const currentTheme = localStorage.getItem('theme');
    const app = document.getElementsByTagName('BODY')[0];
    if (currentTheme == 'light') {
      app.setAttribute('data-theme', 'light');
    } else {
      app.setAttribute('data-theme', 'dark');
    }
  },
  mounted() {
    this.userThemeSet();
  },
  methods: {
    ...mapActions({
      popToast: 'toast/popToast',
    }),
    userThemeSet() {
      if (this.user) {
        localStorage.setItem('page_view', this.user.page_view);
        if (this.user.theme_dark) {
          localStorage.setItem('theme', 'light');
        }
      }
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
