// general routes
const Home = () =>
  import(/* webpackChunkName: "js/HomePage" */ '@pages/Login.vue');
const Users = () =>
  import(/* webpackChunkName: "js/UsersPage" */ '@/views/users/Users.vue');

const Add_user = () =>
  import(/* webpackChunkName: "js/UsersPage" */ '@/views/users/Add_user.vue');
const add_genre = () =>
  import(/* webpackChunkName: "js/UsersPage" */ '@/views/genres/add_genre.vue');
const edit_genre = () =>
  import(
    /* webpackChunkName: "js/UsersPage" */ '@/views/genres/Edit_genre.vue'
  );
const Consoles = () =>
  import(/* webpackChunkName: "js/TermsPage" */ '@views/consoles/Consoles.vue');

const add_console = () =>
  import(
    /* webpackChunkName: "js/UsersPage" */ '@/views/consoles/Add_console.vue'
  );
const edit_console = () =>
  import(
    /* webpackChunkName: "js/UsersPage" */ '@/views/consoles/Edit_console.vue'
  );
const Genres = () =>
  import(/* webpackChunkName: "js/TermsPage" */ '@views/genres/Genres.vue');

const edit_user = () =>
  import(/* webpackChunkName: "js/UsersPage" */ '@/views/users/Edit_user.vue');

const user_profile = () =>
  import(
    /* webpackChunkName: "js/UsersPage" */ '@/views/users/User_profile.vue'
  );

const Roles = () =>
  import(/* webpackChunkName: "js/UsersPage" */ '@/views/roles/Roles.vue');

const Inpermission = () =>
  import(
    /* webpackChunkName: "js/UsersPage" */ '@/views/roles/Permissions.vue'
  );

const Add_roles = () =>
  import(/* webpackChunkName: "js/UsersPage" */ '@/views/roles/Add_roles.vue');
const Edit_roles = () =>
  import(/* webpackChunkName: "js/UsersPage" */ '@/views/roles/Edit_roles.vue');

const Events = () =>
  import(/* webpackChunkName: "js/AboutPage" */ '@views/events/Events.vue');
const Add_event = () =>
  import(/* webpackChunkName: "js/AboutPage" */ '@views/events/Add_event.vue');
const Edit_event = () =>
  import(/* webpackChunkName: "js/AboutPage" */ '@views/events/Edit_event.vue');
const View_event = () =>
  import(/* webpackChunkName: "js/AboutPage" */ '@views/events/View_event.vue');

const Brands = () =>
  import(/* webpackChunkName: "js/TermsPage" */ '@views/brands/Brands.vue');
const Groups = () =>
  import(/* webpackChunkName: "js/TermsPage" */ '@views/groups/Groups.vue');
const Add_Group = () =>
  import(/* webpackChunkName: "js/TermsPage" */ '@views/groups/Add_group.vue');
const View_Group = () =>
  import(/* webpackChunkName: "js/TermsPage" */ '@views/groups/View_Group.vue');
const Gamification = () =>
  import(
    /* webpackChunkName: "js/TermsPage" */ '@views/gamification/Gamification.vue'
  );
const Packages = () =>
  import(/* webpackChunkName: "js/TermsPage" */ '@views/packages/Packages.vue');
const CreatePackage = () =>
  import(
    /* webpackChunkName: "js/TermsPage" */ '@views/packages/CreatePackage.vue'
  );
const ManagePackage = () =>
  import(
    /* webpackChunkName: "js/TermsPage" */ '@views/packages/ManagePackage.vue'
  );
const EditPackage = () => import('@views/packages/EditPackage.vue');
const CampRegistration = () =>
  import('@views/CampRegistration/CampRegistration.vue');
const TournamentUsersRegister = () =>
  import('@views/TournamentRegistrationLeads/TournamentRegistrationLeads.vue');
const TournamentRegister = () =>
  import('@views/TournamentRegistration/TournamentRegistration.vue');
const playerEvaluation = () =>
  import('@views/playerEvaluation/playerEvaluation.vue');
const addScheduled = () => import('@views/Scheduled/add-scheduled.vue');
const editScheduled = () => import('@views/Scheduled/edit-scheduled.vue');
const addEvaluation = () =>
  import('@views/playerEvaluation/add-evaluation.vue');
const editEvaluation = () =>
  import('@views/playerEvaluation/edit-evaluation.vue');
const Games = () =>
  import(/* webpackChunkName: "js/TermsPage" */ '@views/games/Games.vue');
const SearchPage = () =>
  import(/* webpackChunkName: "js/TermsPage" */ '@views/pages/SearchPage.vue');
const add_game = () =>
  import(/* webpackChunkName: "js/UsersPage" */ '@/views/games/Add_game.vue');
const edit_game = () =>
  import(/* webpackChunkName: "js/UsersPage" */ '@/views/games/Edit_game.vue');
const view_game = () =>
  import(/* webpackChunkName: "js/UsersPage" */ '@/views/games/View_game.vue');
const Dashboard = () =>
  import(/* webpackChunkName: "js/DashboardPage" */ '@pages/Dashboard.vue');
const GeneralSettings = () => import('@pages/GeneralSettings.vue');
const Notifications = () => import('@pages/Notifications.vue');
const Locations = () =>
  import(
    /* webpackChunkName: "js/DashboardPage" */ '@views/Locations/Locations.vue'
  );
const add_location = () =>
  import(
    /* webpackChunkName: "js/UsersPage" */ '@/views/Locations/Add_location.vue'
  );
const edit_location = () =>
  import(
    /* webpackChunkName: "js/UsersPage" */ '@/views/Locations/Edit_location.vue'
  );
const Login = () =>
  import(/* webpackChunkName: "js/LoginPage" */ '@pages/Login.vue');
const checkmail = () =>
  import(/* webpackChunkName: "js/LoginPage" */ '@pages/checkmail.vue');
const checkmailverify = () =>
  import(/* webpackChunkName: "js/LoginPage" */ '@pages/checkmailverify.vue');
const Plans = () =>
  import(/* webpackChunkName: "js/LoginPage" */ '@pages/Plans.vue');
const ForgotPassword = () =>
  import(
    /* webpackChunkName: "js/ForgotPasswordPage" */ '@pages/ForgotPassword.vue'
  );
const ResetPassword = () =>
  import(
    /* webpackChunkName: "js/ResetPasswordPage" */ '@pages/ResetPassword.vue'
  );
const Register = () =>
  import(/* webpackChunkName: "js/RegisterPage" */ '@pages/Register.vue');
const VerifyEmail = () =>
  import(/* webpackChunkName: "js/VerifyEmailPage" */ '@pages/VerifyEmail.vue');

const Usersetting = () =>
  import(
    /* webpackChunkName: "js/SettingsPage" */ '@pages/settings/Settings.vue'
  );
const Profile = () =>
  import(
    /* webpackChunkName: "js/ProfilePage" */ '@pages/settings/Profile.vue'
  );
const ViewProfile = () =>
  import(
    /* webpackChunkName: "js/ProfilePage" */ '@pages/settings/ViewProfile.vue'
  );
const Password = () =>
  import(
    /* webpackChunkName: "js/PasswordPage" */ '@pages/settings/Password.vue'
  );
const Account = () =>
  import(
    /* webpackChunkName: "js/AccountPage" */ '@pages/settings/Account.vue'
  );
const NotFound = () =>
  import(/* webpackChunkName: "js/NotFoundPage" */ '@pages/NotFound.vue');

const AdminLayout = () =>
  import(
    /* webpackChunkName: "js/AdminLayoutPage" */ '@/layouts/AdminLayout.vue'
  );
const Admin = () =>
  import(/* webpackChunkName: "js/AdminPage" */ '@/views/admin/Admin.vue');

const Permissions = () =>
  import(
    /* webpackChunkName: "js/PermissionsPage" */ '@/views/admin/Permissions.vue'
  );
const AppSettings = () =>
  import(
    /* webpackChunkName: "js/AppSettingsPage" */ '@/views/admin/AppSettings.vue'
  );
const Swagger = () =>
  import(
    /* webpackChunkName: "js/AppSettingsPage" */ '@/views/swagger/Swagger.vue'
  );
const ViewPackage = () =>
  import(
    /* webpackChunkName: "js/AppSettingsPage" */ '@/views/packages/ViewPackage.vue'
  );
const CampRegistrationLeads = () =>
  import('@views/CampRegistrationLeads/CampRegistrationLeads.vue');
const AddVoucher = () => import('@views/Voucher/AddVoucher.vue');
const EditVouchers = () => import('@views/Voucher/edit-voucher.vue');
const dataDeletion = () => import('@views/pages/data-deletion.vue');
const privacyPolicy = () => import('@views/pages/privacy-policy.vue');
// const PhpInfo = () =>
// import(/* webpackChunkName: "js/PhpInfoPage" */ '@/views/admin/PhpInfo.vue');
import { defineAsyncComponent } from 'vue';

import auth from '@/middleware/auth';
import guest from '@/middleware/guest';
import roleAdmin from '@/middleware/roleAdmin';
import roleSuperAdmin from '@/middleware/roleSuperAdmin';
import roleUser from '@/middleware/roleUser';

export default [
  {
    path: '/',
    // component: () => Home,
    component: Home,
    name: 'home',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/dashboard',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Dashboard,
        name: 'dashboard',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/tournament-register',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: TournamentRegister,
        name: 'tournament-register',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/tournament-users-registered',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: TournamentUsersRegister,
        name: 'tournament-users-registered',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/data-deletion',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: dataDeletion,
        name: 'data-deletion',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/privacy-policy',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: privacyPolicy,
        name: 'privacy-policy',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/camp-register-leads',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: CampRegistrationLeads,
        name: 'campRegisterLead',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/camp-registration',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: CampRegistration,
        name: 'CampRegistration',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/generalsettings',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: GeneralSettings,
        name: 'generalsettings',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/notifications',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Notifications,
        name: 'notifications',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/users',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Users,
        name: 'users',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/groups',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Groups,
        name: 'groups',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/create-group',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Add_Group,
        name: 'Add_group',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/view-group/::id',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: View_Group,
        name: 'view-group',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/swagger',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Swagger,
        name: 'Swagger',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/auth/:provider/callback',
    component: {
      template: '<div class="auth-component"></div>',
    },
  },
  {
    path: '/locations',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Locations,
        name: 'Locations',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/add-location',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: add_location,
        name: 'add-location',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/edit-location/::id',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: edit_location,
        name: 'edit-location',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/add-scheduled',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: addScheduled,
        name: 'add-scheduled',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/edit-scheduled/::id',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: editScheduled,
        name: 'edit-scheduled',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/brands',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Brands,
        name: 'brands',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/search',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: SearchPage,
        name: 'searchpage',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/gamification',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Gamification,
        name: 'gamification',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/games',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Games,
        name: 'games',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/add-game',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: add_game,
        name: 'add-game',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/edit-game/::id',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: edit_game,
        name: 'edit-game',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/games/::slug',
    children: [
      {
        path: '',
        component: view_game,
        name: 'view-game',
      },
    ],
    meta: {},
  },
  {
    path: '/genres',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Genres,
        name: 'Genres',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/consoles',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Consoles,
        name: 'Consoles',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/packages',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Packages,
        name: 'packages',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/player-evaluation',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: playerEvaluation,
        name: 'player-evaluation',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/add-evaluation',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: addEvaluation,
        name: 'add-evaluation',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/edit-evaluation/::id',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: editEvaluation,
        name: 'edit-evaluation',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/view-package/::slug',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: ViewPackage,
        name: 'view_package',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/manage-package/::id',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: ManagePackage,
        name: 'manage_package',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/create_package',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: CreatePackage,
        name: 'create_package',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/edit_package/::id',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: EditPackage,
        name: 'edit_package',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/events',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Events,
        name: 'events',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/add-event',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Add_event,
        name: 'add_event',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/edit-event/::id',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Edit_event,
        name: 'edit_event',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/view-event/::id',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: View_event,
        name: 'view_event',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/users/settings',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Usersetting,
        name: 'Usersetting',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/add-user',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Add_user,
        name: 'add-user',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/add-voucher',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: AddVoucher,
        name: 'add-voucher',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/edit-voucher/:id',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: EditVouchers,
        name: 'edit-voucher',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/add-genre',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: add_genre,
        name: 'add-genre',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/add-console',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: add_console,
        name: 'add-console',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/edit-user/::id',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: edit_user,
        name: 'edit-user',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/user-profile/::id',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: user_profile,
        name: 'user-profile',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/edit-genre/::id',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: edit_genre,
        name: 'edit-genres',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/edit-console/::id',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: edit_console,
        name: 'edit-console',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/roles',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Roles,
        name: 'roles',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/permission',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Inpermission,
        name: 'permission',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/add-roles',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Add_roles,
        name: 'add-roles',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/edit-roles/::id',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Edit_roles,
        name: 'edit-roles',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/plans',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Plans,
        name: 'Plans',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/profile',
    component: AdminLayout,
    children: [
      {
        path: '',
        component: Profile,
        name: 'Profile',
      },
    ],
    meta: {
      middleware: [auth],
    },
  },

  {
    path: '/login',
    component: Login,
    name: 'login',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/checkmail',
    component: checkmail,
    name: 'Checkmail',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/checkmailverify',
    component: checkmailverify,
    name: 'Checkmailverify',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'forgot-password',
    meta: {
      middleware: [guest],
    },
  },

  {
    path: '/reset-password/:token',
    props: (route) => ({
      token: route.params.token,
      email: route.query.email,
    }),
    component: ResetPassword,
    name: 'reset-password',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/register',
    component: Register,
    name: 'register',
    meta: {
      middleware: [guest],
    },
  },

  {
    path: '/verify-email/:id/:hash',
    props: (route) => ({
      id: route.params.id,
      hash: route.params.hash,
    }),
    component: VerifyEmail,
    name: 'verify-email',
  },
  {
    path: '/@:slug',
    component: ViewProfile,
    name: 'ViewProfile',
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'NotFound',
  },
];
