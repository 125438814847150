import { createRouter, createWebHistory } from 'vue-router';
import { trackRouter } from 'vue-gtag-next';
import routes from '@/router/routes.js';
import store from '@/store';
import middlewarePipeline from '@/middleware/middlewarePipeline';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.state.auth.user) {
    const inrout = to.name;
    const usr_perm = store.state.auth.user.permissions;
    if (
      inrout == 'generalsettings' ||
      inrout == 'view_package' ||
      inrout == 'CampRegistration'
    ) {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      script.async = true;
      document.head.appendChild(script);
    }
    if (inrout == 'roles') {
      if (usr_perm.indexOf('view.roles') == -1) {
        return next('Login');
      }
    }
  }
  if (!to.meta.middleware) {
    return next();
  }

  const { middleware } = to.meta;

  const context = {
    to,
    from,
    store,
    next,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

trackRouter(router);

export default router;
