import { createApp } from 'vue';
import App from '@views/App.vue';
import router from '@router';
import store from '@store';
import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import { plugin as VueTippy } from 'vue-tippy';
import AppButton from '@components/common/AppButton.vue';
import AppToast from '@components/common/AppToast.vue';
import AppSwitch from '@components/common/AppSwitch.vue';
// import AppTable from '@components/common/AppTable.vue';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ClientTable } from 'v-tables-3';
import VueSecureHTML from 'vue-html-secure';
import './bootstrap';
import { i18nVue } from 'laravel-vue-i18n';

axios.defaults.withCredentials = true;

const app = createApp(App);
store.dispatch('auth/getLogins').then(() => {});
store.dispatch('auth/getUser').then(() => {
  app
    .use(store)
    .use(router)
    .use(VueSweetalert2)
    .use(ClientTable, {}, 'tailwind')
    .use(i18nVue, {
      resolve: async (lang) => {
        const langs = import.meta.glob('../../lang/*.json');
        return await langs[`../../lang/${lang}.json`]();
      },
    })
    .use(VueTippy, {
      directive: 'tippy', // => v-tippy
      component: 'tippy', // => <tippy/>
      componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
      defaultProps: {
        arrow: true,
        theme: 'material',
        animation: 'perspective',
        placement: 'auto-end',
        allowHTML: true,
      },
    })
    .use(VueSecureHTML)
    .component('AppButton', AppButton)
    .component('AppToast', AppToast)
    // .component('AppTable', AppTable)
    .component('AppSwitch', AppSwitch);

  app.mount('#app');
});
